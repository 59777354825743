@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color:#1F2937;
  display: inline;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blob{
  border-radius: 94% 31% 30% 67% / 67% 37% 56% 34%;
  width:100%;
  /* height: 450px; */
  /* background: #cd608a; */
  /* box-shadow: -10vmin 10vmin 0 rgba(255,255,255,0.07); */
  animation: animateBlob 10s linear infinite alternate;
}

.diagonalCross2 {
  background: linear-gradient(to top right, #fff calc(50% - 1px), black , #fff calc(50% + 2px) )
}